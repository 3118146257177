<template>
  <div class="contacts">
    <div class="contacts__item email">
      <span class="contacts__item_title">Email:</span>
      <a class="contacts__item_contact" href="mailto:art@browngrotta.com">art@browngrotta.com</a>
    </div>
    <div class="contacts__item phone">
      <span class="contacts__item_title">Phone:</span>
      <a class="contacts__item_contact" href="tel:12038340683">203.834.0683</a>
    </div>
    <!-- <div class="contacts__item address">
      <span class="contacts__item_title">We're located at:</span>
      <span class="contacts__item_contact">276 Ridgefield Road, Wilton, CT 06897</span>
    </div> -->
    <div v-if="!isGalleryOpen" class="contacts__item notification">
      <NotificationMarker class="contacts__item_icon" />
      <span class="contacts__item_title"
        >Gallery Is Not Open Outside Of Exhibition Hours For Visits</span
      >
    </div>
    <!-- <div v-if="nextExhibition" class="contacts__item exhibition">
      <span class="contacts__item_title">Next exhibition is:</span>
      <span class="contacts__item_contact">{{ nextExhibition.dates }}</span>
    </div> -->
    <!-- <BaseButton
      v-if="nextExhibition"
      class="button--xs button--white"
      text="MORE INFORMATION"
      @click.native="$router.push(`/exhibitions/${nextExhibition.slug}`)"
    /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NotificationMarker from "@/components/NotificationMarker.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: { NotificationMarker, BaseButton },

  props: {
    isGalleryOpen: { type: Boolean, default: false },
  },

  data() {
    return {};
  },

  // async created() {
  //   await this.apiGetNextExhibition();
  // },

  // computed: {
  //   ...mapGetters("publicapi/contact", {
  //     nextExhibition: "getNextExhibition",
  //   }),
  // },

  // methods: {
  //   ...mapActions("publicapi/contact", ["apiGetNextExhibition"]),
  // },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 18px;
  line-height: 1.33;
  @media screen and (max-width: $xs) {
    font-size: 16px;
    line-height: 1.56;
  }
}

.contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 4rem;
    @media screen and (max-width: $xs) {
      margin-bottom: 3rem;
    }

    &_title {
      font-size: 16px;
      line-height: 1.56;
      text-transform: uppercase;
      @media screen and (max-width: $xs) {
        width: 100%;
      }
    }

    &_contact {
      font-size: 30px;
      line-height: 1.2;
      text-decoration: none;
      @media screen and (max-width: $xs) {
        font-size: 25px;
        line-height: 1.05;
      }
    }

    &.email {
      width: max-content;
    }

    // &.phone {
    //   width: 50%;
    //   margin-left: 10.5rem;
    //   @media screen and (max-width: $lg) {
    //     width: 100%;
    //     margin-left: 0rem;
    //   }
    // }

    &.notification {
      flex-direction: row;
      align-items: center;

      .contacts__item_icon {
        margin-right: 11px;
      }
    }
  }
}
</style>
