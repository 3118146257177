var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-us"},[_c('h2',{staticClass:"contact-us__title text-title"},[_vm._v("Contact Us")]),_c('p',{staticClass:"contact-us__description text-description"},[_vm._v(" To purchase artwork featured on this website or to obtain information about other available works, please contact browngrotta arts. ")]),_c('ValidationObserver',{ref:"observer",staticClass:"contact-us__form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{staticClass:"contact-us__input",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SubscriptionInput',{staticClass:"form-control--inquire",attrs:{"placeholder":"Your Name","validateError":errors[0]},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"contact-us__input",attrs:{"tag":"div","rules":{ required: true, email: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SubscriptionInput',{staticClass:"form-control--inquire",attrs:{"placeholder":"Email Address","validateError":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"contact-us__input",attrs:{"tag":"div","rules":{ required: true, phone_number: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SubscriptionInput',{staticClass:"form-control--inquire",attrs:{"placeholder":"Phone Number","validateError":errors[0]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"contact-us__input",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BGATextarea',{staticClass:"form-control--inquire",attrs:{"placeholder":"Message","maxLength":255,"validateError":errors[0]},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})]}}],null,true)}),_c('BaseButton',{staticClass:"contact-us__button button--white",attrs:{"text":"SUBMIT","disabled":invalid},on:{"click":_vm.submit}})]}}])}),_c('Contacts',{staticClass:"contact-us__contacts"}),_c('SignUpForUpdates',{staticClass:"contact-us__sign-up"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }